
const ACCESS_TOKEN = 'USER_ACCESS_TOKEN';
const REFRESH_TOKEN = 'USER_REFRESH_TOKEN';
const Alpha_User = 'Alpha_User_Data';
const Alpha_lang = 'Alpha_lang';
export const getAccessToken = () => localStorage.getItem(ACCESS_TOKEN);

export const setAccessToken = (token) => {
  localStorage.setItem(ACCESS_TOKEN, token);
}

export const removeAccessToken = () => {
  localStorage.removeItem(ACCESS_TOKEN);
}

export const setRefreshToken = (token) => {
  localStorage.setItem(REFRESH_TOKEN, token);
}

export const getRefreshToken = () => localStorage.getItem(REFRESH_TOKEN);

export const removeRefreshToken = () => {
  localStorage.removeItem(REFRESH_TOKEN);
}

export function setUserData(data) {
  return localStorage.setItem(Alpha_User, data);
}
// export function getUserData() {
//   return JSON.parse(localStorage.getItem(Alpha_User))
// }
export function getUserData() {
  return localStorage.getItem(Alpha_User);
}

export function setUserLang(lang) {
  return localStorage.setItem(Alpha_lang, lang);
}
export function getUserLang(){
  return localStorage.getItem(Alpha_lang)
}
export function removeUserLang(){
  return localStorage.removeItem(Alpha_lang);
}
