import { getCountries } from '@/api/countries/countries'
import { getPaymentCurrencies, getPaymentTypes } from '@/api/payment-types/payment-types';
import {} from '@/api/payment-types/payment-types'

export default {
  namespaced: true,
  state: {
    loading: false,
    countries: [],
    currencies: [],
    paymentType: [],
  },
  getters: {
    loading: state => state.loading,
    currencies(state) {
      return state.currencies
    },
    paymentType(state) {
      return state.paymentType
    }
  },
  mutations: {
    CHANGE_LOADING(state, value) {
      state.loading = value;
    },
    SET_COUNTRIES(state, payload) {
      state.countries = payload;
    },
    SET_CURRENCIES(state, payload) {
      state.currencies = payload;
    },
    SET_PAYMENT_TYPE(state, payload) {
      state.paymentType = payload.data
    }
  },
  actions: {
    setLoading({ commit }, value) {
      commit('CHANGE_LOADING', value)
    },
    fetchCountries({commit}) {
      getCountries().then(res => {
        commit('SET_COUNTRIES', res.data.data.data);
      })
    },
    fetchCurrencies({commit}) {
      return new Promise(resolve => {
        getPaymentCurrencies().then(res => {
          commit('SET_CURRENCIES', res.data.data);
          resolve(res)
        })
      })
    },
    fetchMethods({commit}) {
      getPaymentTypes().then( res => {
        commit('SET_PAYMENT_TYPE', res.data)
      })
    }
  }
}
