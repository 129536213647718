// eslint-disable-next-line import/no-cycle
import request from '../libs/axios'

export function loginTheUser(user) {
  return request({
    url: '/api/login-user',
    method: 'post',
    data: user,
  })
}

export function getMe() {
  return request({
    url: '/api/user',
    method: 'get',
  })
}

export function refreshToken(data) {
  return request({
    url: '/api/auth/token/refresh',
    method: 'post',
    data,
  })
}

export function logoutUser(data) {
  return request({
    url: '/api/logout',
    method: 'post',
    data,
  })
}

export function fetchUsersList(params) {
  return request({
    url: '/api/users',
    method: 'get',
    params,
  })
}

export function storeUser(data) {
  return request({
    url: '/api/auth/users',
    method: 'post',
    data,
  })
}

export function updateUser(data) {
  const id = data instanceof FormData ? data.get('id') : data.id
  return request({
    url: `/api/auth/update-user/${id}`,
    method: 'put',
    data,
  })
}

export function deleteUser(params) {
  return request({
    url: `/api/auth/users/${params.id}`,
    method: 'delete',
  })
}

export function userDetail(params) {
  return request(({
    url: `/api/auth/users/${params.id}`,
    method: 'get',
  }))
}
