import request from '../../libs/axios'

export function getPaymentTypes(){
  return request({
    url: '/api/payment-types',
    method: 'get',
  })
}

export function createPaymentTypesById(){
  return request({
    url:'/api/payment-types',
    method:'get'
  })
}

export function getPaymentTypesById(data){
  return request({
    url: `/api/payment-types/${data.id}`,
    method: 'get',
  })
}


export function addPaymentTypes(data){
  return request({
    url: '/api/payment-types',
    method: 'post',
    data,
  })
}

export function editPaymentTypes(data){
  return request({
    url: `/api/payment-types/${data.id}`,
    method: 'put',
    data,
  })
}

export function deletePaymentTypes(data){
  return request({
    url: `/api/payment-types/${data.id}`,
    method: 'delete'
  })
}

export function getPaymentTypeById(id) {
  return request({
    url: `/api/roles/${id}`,
    method:'get',
  })
}

export function getPaymentById(id) {
  return request({
    url: `/api/payment-types/${id}`,
    method:'get',
  })
}

export function getPaymentCurrencies() {
  return request({
    url: '/api/payment-currencies',
    method: 'get',
  });
}

export function getPaymentExpences() {
  return request({
    url: '/api/payment-expences',
    method: 'get',
  })
}

export function storePaymentExpence(data) {
  return request({
    url: '/api/payment-expences',
    method:'post',
    data
  });
}

export function showPaymentExpence(id) {
  return request({
    url: `/api/payment-expences/${id}`,
    method:'get',
  });
}

export function updatePaymentExpence(data) {
  return request({
    url: `/api/payment-expences/${data.id}`,
    method:'put',
    data,
  })
}

export function deletePaymentExpence(id) {
  return request({
    url: `/api/payment-expences/${id}`,
    method: 'delete',
  });
}

export function fetchContractItem(data) {
  return request({
    url:'/api/get-contract-services',
    method: 'post',
    data,
  })
}
