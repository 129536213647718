import request from '../../libs/axios'
import fileRequest from '../../libs/axios-file'

export function getCountries(params){
  return request({
    url: '/api/countries',
    method: 'get',
    params,
  })
}

export function createCountryById(){
  return request({
    url:'/api/countries',
    method:'get'
  })
}

export function getCountryById(data){
  return request({
    url: `/api/countries/${data.id}`,
    method: 'get',
  })
}


export function addCountries(data){
  return fileRequest({
    url: '/api/countries',
    method: 'post',
    data,
  })
}

export function editCountries(data){
  return request({
    url: `/api/countries/${data.get('id')}`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    data,
  })
}

export function deleteCountries(data){
  return request({
    url: `/api/countries/${data.id}`,
    method: 'delete'
  })
}

export function getCountriesById(id) {
  return request({
    url: `/api/countries/${id}`,
    method:'get',
  })
}
