import { getClientsById } from '@/api/clients/clients'

export default {
  namespaced: true,
  state: {
    clientDetail: null,
  },
  getters: {
    client(state) {
      return state.clientDetail
    }
  },
  mutations: {
    SET_CLIENT_DETAIL(state, payload) {
      state.clientDetail = payload;
    },
    DELETE_CLIENT(state) {
      state.clientDetail = {};
    }
  },
  actions: {
    fetchClientDetail({commit}, params) {
      return new Promise((resolve, reject) => {
        getClientsById(params.id).then(res => {
          commit('SET_CLIENT_DETAIL', res.data.data);
          resolve(res);
        }).catch(err => {
          reject(err);
        })
      })
    },
    clearClientDetail({commit}) {
      commit('DELETE_CLIENT')
    }
  }
}
