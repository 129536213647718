import Vue from 'vue'
import moment from 'moment'


Vue.filter('dateFormat', function(val) {
  return moment(val).format('DD.MM.YYYY')
})

Vue.filter('dateTimeFormat', function(val) {
  return moment(val).format('DD.MM.YYYY HH:mm')
})

Vue.filter('moneyDisplayFormat', function(val) {
  if(val === 0) {
    return 0;
  }
  else {
    var data = '';
    var splitted = val ? val.toString().split("") : '';
    var delimeter = splitted.length % 3;
    var delCount = delimeter > 0 ? delimeter : 3;
    var k = 0;
    var price = '';
    for(var i=0;i<splitted.length;i++) {
      if(delCount === k) {
        price += ' ';
        k=0;
        delCount = 3;
      }
      price += splitted[i];
      k++;
    }
    return price;
  }
})
