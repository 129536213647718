import request from '../../libs/axios'

export function getClients(params){
  return request({
    url: '/api/clients',
    method: 'get',
    params,
  })
}

export function addClients(data){
  return request({
    url: '/api/clients',
    method: 'post',
    data,
  })
}

export function editClients(data){
  return request({
    url: `/api/clients/${data.get("id")}`,
    method: 'post',
    data,
  })
}

export function deleteClients(data){
  return request({
    url: `/api/clients/${data.id}`,
    method: 'delete'
  })
}

export function getClientsById(id) {
  return request({
    url: `/api/clients/${id}`,
    method:'get',
  })
}
