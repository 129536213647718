import { loginTheUser, refreshToken, logoutUser, getMe } from '@/api/auth'
import {
  setAccessToken, setRefreshToken, getAccessToken, removeAccessToken, removeRefreshToken, getRefreshToken, setUserData,
} from '@/libs/auth'

export default {
  namespaced: true,
  state: {
    authUser: null,
  },
  getters: {
    activeUser(state) {
      if(state.authUser !== null) {
        state.authUser.contacts = JSON.parse(state.authUser.contacts)
      }
      return state.authUser
    }
  },
  mutations: {
    SET_JWT_TOKEN(state, payload) {
    },
    setAuthUser(state, payload) {
      state.authUser = payload;
    },
    CLEAR_USER_AUTH(state) {
      state.authUser = null;
    }
  },
  actions: {
    jwtLogin({ commit }, user) {
      return new Promise((resolve, reject) => {
        loginTheUser(user).then(res => {
          setAccessToken(res.data)
          setRefreshToken(res.data.access_token)
          const userData = res.data
          delete userData.access_token
          setUserData(JSON.stringify(userData))
          resolve(res)
        }).catch(err => {
          reject(err)
        })
      })
    },
    fetchAccessToken() {
      const data = { refresh: getRefreshToken() }
      return new Promise((resolve, reject) => {
        refreshToken(data).then(res => {
          setAccessToken(res.data.access)
          // setRefreshToken(res.data.refresh_token);
          resolve(res)
        }).catch(err => {
          reject(err)
        })
      })
    },
    logoutTheUser() {
      const data = { refresh: getRefreshToken() }
      return new Promise((resolve, reject) => {
        logoutUser(data).then(res => {
          removeAccessToken()
          removeRefreshToken()
          resolve(res)
        }).catch(err => {
          reject(err)
        })
      })
    },
    fetchMe({commit}) {
      getMe().then(res => {
        commit('setAuthUser', res.data);
      })
    },
    fetchLogoutAuth({commit}) {
      commit('CLEAR_USER_AUTH')
    }
  },
}
