import Vue from 'vue'
import VueRouter from 'vue-router'

import guest from '../middleware/guest'
import auth from '../middleware/auth'
import middlewarePipeline from '../middleware/middlewarePipeline'
import store from '../store'
import directories from '@/router/directories'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/profile/ProfileHeader.vue'),
      meta: {
        pageTitle: 'Home',
        middleware: [auth],
      },
    },
    {
      path:'/profile',
      name:'profile',
      component: () => import('@/views/profile/ProfileHeader.vue'),
      meta: {
        pageTitle: 'Profile',
        middleware: [auth],
      },
    },
    {
      path:'/tours',
      name:'tours',
      component: () => import('@/views/tours/index'),
      meta: {
        pageTitle: 'Tours',
        middleware: [auth],
      },
    },
    {
      path: '/tours/add-tours',
      name: "add-tours",
      component: () => import('@/views/tours/components/TuorsAdd'),
      meta: {
        pageTitle: 'Tours',
        middleware: [auth],
      },
    },
    {
      path: '/tours/:tourDetailsId',
      name: "Tour-Details",
      component: () => import('@/views/tours/components/TourDetails'),
      meta: {
        pageTitle: 'Tours',
        middleware: [auth],
      },
    },
    {
      path: '/tours/add-tours/:id',
      name: "add-tours-id",
      component: () => import('@/views/tours/components/TuorsAdd'),
      meta: {
        pageTitle: 'Tours',
        middleware: [auth],
      },
    },
    {
      path: '/tours/update-tours/:tourId',
      name: "update-tours",
      component: () => import('@/views/tours/components/TuorsAdd'),
      meta: {
        pageTitle: 'Tours',
        middleware: [auth],
      },
    },
    {
      path:'/avia-tickets-sales',
      name:'avia-tickets-sales',
      component: () => import('@/views/avia-tickets-sales/index'),
      meta: {
        pageTitle: 'Avia Tickets',
        middleware: [auth],
      },
    },
    {
      path:'/avia-tickets-sales/:ticketDetailsId',
      name:'ticket-details',
      component: () => import('@/views/avia-tickets-sales/components/TicketDetail'),
      meta: {
        pageTitle: 'Avia Tickets',
        middleware: [auth],
      },
    },
    {
      path:'/avia-tickets-sale/ticket-add',
      name:'ticket-add',
      component: () => import('@/views/avia-tickets-sales/components/TicketAdd'),
      meta: {
        pageTitle: 'Avia Tickets',
        middleware: [auth],
      },
    },
    {
      path:'/avia-tickets-sales/ticket-add/:id',
      name:'ticket-add-id',
      component: () => import('@/views/avia-tickets-sales/components/TicketAdd'),
      meta: {
        pageTitle: 'Avia Tickets',
        middleware: [auth],
      },
    },
    {
      path:'/avia-tickets-sales/ticket-update/:ticketId',
      name:'ticket-update',
      component: () => import('@/views/avia-tickets-sales/components/TicketAdd'),
      meta: {
        pageTitle: 'Avia Tickets',
        middleware: [auth],
      },
    },
    {
      path:'/visa',
      name:'visa',
      component: () => import('@/views/visa/index'),
      meta: {
        pageTitle: 'Visa',
        middleware: [auth],
      },
    },
    {
      path:'/visa/visa-details/:visaDetailsId',
      name:'visa-details',
      component: () => import('@/views/visa/components/VisaDetails'),
      meta: {
        pageTitle: 'Visa',
        middleware: [auth],
      },
    },
    {
      path:'/visa/visa-add',
      name:'visa-add',
      component: () => import('@/views/visa/components/VisaAdd'),
      meta: {
        pageTitle: 'Visa',
        middleware: [auth],
      },
    },
    {
      path:'/visa/visa-add/:id',
      name:'visa-add-id',
      component: () => import('@/views/visa/components/VisaAdd'),
      meta: {
        pageTitle: 'Visa',
        middleware: [auth],
      },
    },
    {
      path:'/visa/visa-update/:visaId',
      name:'visa-update',
      component: () => import('@/views/visa/components/VisaAdd'),
      meta: {
        pageTitle: 'Visa',
        middleware: [auth],
      },
    },
    {
      path:'/insurance',
      name:'insurance',
      component: () => import('@/views/insurance/index'),
      meta: {
        pageTitle: 'Insurance',
        middleware: [auth],
      },
    },
    {
      path:'/insurance/insurance-details/:insuranceDetailsId',
      name:'insurance-details',
      component: () => import('@/views/insurance/components/InsuranceDetails'),
      meta: {
        pageTitle: 'Insurance',
        middleware: [auth],
      },
    },
    {
      path:'/insurance/insurance-add',
      name:'insurance-add',
      component: () => import('@/views/insurance/components/InsuranceAdd'),
      meta: {
        pageTitle: 'Insurance',
        middleware: [auth],
      },
    },
    {
      path:'/insurance/insurance-add/:id',
      name:'insurance-add-id',
      component: () => import('@/views/insurance/components/InsuranceAdd'),
      meta: {
        pageTitle: 'Insurance',
        middleware: [auth],
      },
    },
    {
      path:'/insurance/insurance-update/:insuranceId',
      name:'insurance-update',
      component: () => import('@/views/insurance/components/InsuranceAdd'),
      meta: {
        pageTitle: 'Insurance',
        middleware: [auth],
      },
    },
    {
      path: '/payments',
      name: 'Payments',
      component: () => import('@/views/payments/Payments'),
      meta: {
        pageTitle: 'Payments',
        middleware: [auth],
      }
    },
    {
      path: '/contracts',
      name: 'Contracts',
      component: () => import('@/views/payments/Contracts'),
      meta: {
        pageTitle: 'Contracts',
        middleware: [auth],
      }
    },
    {
      path: '/collection/tours',
      name: 'CollectionTours',
      component: () => import('@/views/collection/CTour'),
      meta: {
        pageTitle: 'Tours',
        middleware: [auth],
      }
    },
    {
      path: '/collection/ticket',
      name: 'CollectionTicket',
      component: () => import('@/views/collection/CTicket'),
      meta: {
        pageTitle: 'Avia Tickets',
        middleware: [auth],
      }
    },
    {
      path: '/collection/insurance',
      name: 'CollectionInsurance',
      component: () => import('@/views/collection/CInsurance'),
      meta: {
        pageTitle: 'Insurance',
        middleware: [auth],
      }
    },

    ...directories,
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
        middleware: [guest],
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
        middleware: [guest],
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, from, next) => {
  if (!to.meta.middleware) {
    next()
  }
  const { middleware } = to.meta
  const context = {
    to,
    from,
    next,
    store,
  }
  if (middleware) {
    return middleware[0]({
      ...context,
      next: middlewarePipeline(context, middleware, 1),
    })
  }
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
