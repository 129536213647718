import guest from '../middleware/guest'
import auth from '../middleware/auth'


export default [
  {
    path: '/directories/clients',
    name: 'DirectoriesClients',
    component: () => import('@/views/directories/clients/index'),
    meta: {
      pageTitle: 'Clients',
    },
  },
  {
    path: '/directories/clients-details/:id',
    name: 'DirectoriesClientDetail',
    component: () => import('@/views/directories/clients/detail'),
    meta: {
      pageTitle: 'ClientDetail',
    },
  },
  {
    path: '/directories/clients-detail/add-client',
    name: 'ClientPost',
    component: () => import('@/views/directories/clients/components/clients/AddClient'),
    meta: {
      pageTitle: 'ClientDetail',
    },
  },
  {
    path: '/directories/clients-detail/:clientPutId',
    name: 'ClientUpdate',
    component: () => import('@/views/directories/clients/components/clients/AddClient'),
    meta: {
      pageTitle: 'ClientDetail',
    },
  },
  {
    path: '/directories/branchs',
    name: 'DirectoriesBranchs',
    component: () => import('@/views/directories/branch/index'),
    meta: {
      pageTitle: 'Branchs',
    },
  },
  {
    path: '/directories/roles',
    name: 'DirectoriesRoles',
    component: () => import('@/views/directories/roles/index'),
    meta: {
      pageTitle: 'Roles',
    },
  },
  {
    path:'/expense-type',
    name:'ExpenseType',
    component: () => import('@/views/directories/expense-type/index'),
    meta: {
      pageTitle: 'Expense_types',
    },
  },
  {
    path:'/customer-source',
    name:'CustomerSource',
    component: () => import('@/views/directories/customer-source/index'),
    meta: {
      pageTitle: 'Customer_source',
    },
  },
  {
    path:'/payment-method',
    name:'PaymentMethod',
    component: () => import('@/views/directories/payment-method/index'),
    meta: {
      pageTitle: 'Payment_method',
    },
  },
  {
    path:'/partners',
    name:'Partners',
    component: () => import('@/views/directories/partners/index'),
    meta: {
      pageTitle: 'Partners',
    },
  },
  {
    path:'/country',
    name:'Country',
    component: () => import('@/views/directories/country/index')
  },
  {
    path:'/regions',
    name:'Regions',
    component: () => import('@/views/directories/regions/index')
  },
  {
    path:'/users',
    name:'Users',
    component: () => import('@/views/directories/users/index')
  },
  {
    path:'/hotels',
    name:'Hotels',
    component:() => import('@/views/directories/hotels/index')
  },
  {
   path:'/tickets',
   name:'Tickets',
   component: () => import('@/views/directories/avia-tickets/index')
  },
  {
    path:'/branch-reports',
    name:'BranchReports',
    component: () => import('@/views/reports/BranchReport')
  },
  {
    path:'/tour-reports',
    name:'TourReports',
    component: () => import('@/views/reports/TourReport')
  },
  {
    path:'/avia-ticket-reports',
    name:'AviaTicketReports',
    component: () => import('@/views/reports/AviaticketReport')
  },
  {
    path:'/insurance-reports',
    name:'InsuranceReports',
    component: () => import('@/views/reports/InsuranceReport')
  },
  {
    path:'/visa-reports',
    name:'VisaReports',
    component: () => import('@/views/reports/VisaReports')
  },
  {
    path:'/payment-reports',
    name:'PaymentReports',
    component: () => import('@/views/reports/PaymentReport')
  },
  {
    path:'/operator-daily-reports',
    name:'OperatorDailyReports',
    component: () => import('@/views/reports/OperatorDaily')
  },
  {
    path:'/incomes-and-outcomes',
    name:'IncomesAndOutcomes',
    component: () => import('@/views/reports/IncomesAndOutcomes')
  },
  {
    path:'/directories/client-sources',
    name:'ClientSources',
    component: () => import('@/views/directories/client_sources/index')
  },
  {
    path: '/payment-expences',
    name: 'PaymentExpence',
    component: () => import('@/views/payments/Expences'),
    meta: {
      middleware: [auth],
    }
  },
  {
    path: '/avia-companies',
    name: 'avia-companies',
    component: () => import('@/views/directories/avia-companies/index'),
    meta: {
      middleware: [auth],
    }
  },
  {
    path: '/country/country-visas',
    name: 'CountryVisasList',
    component: () => import('@/views/directories/country-visas/index.vue'),
    meta: {
      middleware: [auth],
    }
  },
  {
    path: '/country/country-visas/:id',
    name: 'CountryVisasDetail',
    component: () => import('@/views/directories/country-visas/details.vue'),
    meta: {
      middleware: [auth],
    }
  },
  {
    path: '/currency',
    name: 'CurrencyIndex',
    component: () => import('@/views/directories/currency/index.vue'),
    meta: {
      middleware: [auth],
    },
  },
  {
    path: '/visa-vat',
    name: 'VisaVATIndex',
    component: () => import('@/views/directories/vat/index.vue'),
    meta: {
      middleware: [auth],
    },
  },
  
]
